<template>
  
  <v-carousel 
    light
    v-model="model"   
    :height="$vuetify.breakpoint.xs? `550` : `530`"
    hide-delimiter-background
    hide-delimiters
    :show-arrows='false'
    >
    <v-carousel-item
      v-for="(item,i) in links"
      :key="i"
    
    >
      <v-card  class="text-center"  :height="$vuetify.breakpoint.xs? `570` : `530`" >
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            class="pt-2 my-0"
            height="40px"
          >
            <v-icon v-if="false" large @click="model=0" >mdi-arrow-left</v-icon>

              <v-spacer></v-spacer>

              <v-btn
                color="white"
                icon
              >
                <v-icon @click="close">mdi-close</v-icon>
              </v-btn>
          </v-app-bar>

        <div style="width:100%; height:100%; " class="mt-0 pt-0">
          <v-card-title 
            width="100%"
            class="text-h5 font-weight-black pb-4 text-center pt-0"
          >
            <div v-if="type=='navi'||type=='publish'" style="width:100%; padding:0;">
              <v-icon color="black" large>mdi-map-marker</v-icon>ナビゲーション
            </div>
            <div v-else style="width:100%; padding:0;">
              <v-icon color="black" large>mdi-package-variant-closed</v-icon>コンテンツ
            </div>
          </v-card-title>
          <v-card-text >
             <CardTutorial
              :type="type"          
              :item="links[type]" 
              />
          </v-card-text>
        </div>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>


const CardTutorial = () => import('@/components/CardTutorial.vue');

  export default {
    name: 'CarouselTutorial',
    components:{CardTutorial},
    props:["type"],
    data: () => ({
      model: 0,
      // target:{},
      links:{
        "top":
          {
            title: "トップ",
            name:"top",
          },
        "navi":
          {
            title: "ナビ",
            name:"navi",
          },

        "object":
          {
            title: "3Dモデル",
            name:"content_upload",
          },
        "image":  
          {
            title: "画像",
            name:"image_upload",
          },
        "pdf":
          {
            title: "PDF",
            name:"content_name_change",
          },
          "sound":
          {
            title: "音声",
            name:"content_delete",
          },
          "movie":
          {
            title: "動画",
            name:"content_change_thumb",
          },
          "url":
          {
            title: "URL",
            name:"show_3d",
          },
          "publish":
          {
            title: "公開設定",
            name:"publish",
          },
     
      },
    }
    
    ),

      methods:{
      close(){
        this.model = 0;
        this.$emit("closeEmit")
      },

      moveTuto(index){
        this.model = index+1;
        // this.target = this.links[this.type][index];
      },

    }

  


    
   
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

  .v-btn .v-btn__content .v-icon {
        color: black;
  }

</style>

